import React, { useState } from "react";
import { injectIntl } from "gatsby-plugin-intl";
import { useStaticQuery, graphql } from "gatsby";

import * as styles from "./footer.module.scss";

const Footer = ({ intl }) => {
  const data = useStaticQuery(graphql`
    query {
      allSanitySiteSettings {
        nodes {
          facebook
          instagram
          address
          phone
          email
        }
      }
    }
  `)

  const siteSettings = data.allSanitySiteSettings.nodes[0]

  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className={`${styles.content}`}>
              <hr />
              <a
                target="_blank"
                rel="noreferrer"
                href={siteSettings.facebook}
              >
                Facebook
              </a>{" "}
              /{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href={siteSettings.instagram}
              >
                Instagram
              </a>{" "}
              / Florêncio da Costa — {siteSettings.phone} /
              <a href={'mailto:'+siteSettings.email}>
                {siteSettings.email}
              </a>{" "}
              / {siteSettings.address}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default injectIntl(Footer);
