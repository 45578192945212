import React from "react";
import Header from "./header/header";
import Footer from "./footer";
// import Seo from "./seo";

import * as styles from "./layout.module.scss";

export default function Layout({ children }) {
  return (
    <div className="default-layout">
      {/* <Seo></Seo> */}
      <Header></Header>
      <main className={styles.main}>
        {children}
      </main>
      <Footer></Footer>
    </div>
  );
}
