import React from "react";
import { injectIntl, Link, changeLocale } from "gatsby-plugin-intl";
import { globalHistory as history } from "@reach/router";

import * as styles from "./header.module.scss";
import logo from "./img/logo.png";

const activeLink = (currentPath, path) => {
  return currentPath === path ? "active" : "";
};

const activeLanguage = (currentLocale, locale) => {
  return currentLocale === locale ? "active" : "";
};

const Header = ({ intl }) => {
  return (
    <header className={styles.header}>
      <div className="container-sm">
        <nav className="navbar navbar-light navbar-expand-md pl-0 pr-0">
          {/* Logo */}
          <Link to="/" className="navbar-brand">
            {/* TODO: add alt data from metadata */}
            <img
              className={styles.logo}
              src={logo}
              alt="Florêncio da Costa - Oficina d'Arquitectura"
            />
          </Link>

          {/* Toggler */}
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            {/* Navigation */}
            <ul className="navbar-nav ms-auto mt-3 mt-md-0">
              {/* GLOBAL MENU */}
              <li className={`${styles.navItem} nav-item`}>
                <Link
                  className={activeLink(
                    history.location.pathname,
                    `/${intl.locale}/`
                  )}
                  to="/"
                >
                  {intl.formatMessage({ id: "menu_projects" })}
                </Link>
              </li>
              {/* <li className={`${styles.navItem} nav-item`}>
                <Link className="disabled">
                  {intl.formatMessage({ id: "menu_reviews" })}
                </Link>
              </li> */}
              <li className={`${styles.navItem} nav-item`}>
              <Link
                  className={activeLink(
                    history.location.pathname,
                    `/${intl.locale}/about`
                  )}
                  to="/about"
                >
                  {intl.formatMessage({ id: "menu_about" })}
                </Link>
              </li>
              {/* DIVIDER */}
              <li className={styles.divider}>/</li>
              {/* I18N */}
              <li className={`${styles.navItem} nav-item`}>
                <button
                  className={`${activeLanguage(
                    intl.locale,
                    "pt"
                  )} btn btn-link ${styles.navItemI18n}`}
                  onClick={() => changeLocale("pt")}
                >
                  pt
                </button>
              </li>
              <li className={`${styles.navItem} nav-item`}>
                <button
                  className={`${activeLanguage(
                    intl.locale,
                    "en"
                  )} btn btn-link ${styles.navItemI18n}`}
                  onClick={() => changeLocale("en")}
                >
                  en
                </button>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default injectIntl(Header);
