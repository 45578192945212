import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { injectIntl, Link } from "gatsby-plugin-intl";
import { GatsbyImage } from "gatsby-plugin-image";
import { getIntlData } from "../lib/intl";

import Layout from "../components/layout";

const AboutPage = ({data, intl}) => {
    const siteSettings = data.allSanitySiteSettings.nodes[0]

    return (
        <Layout>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-6">
                        <GatsbyImage
                            image={data.allSanityAbout.nodes[0].image.asset.gatsbyImageData}
                            layout="fixed"
                            className="w-100 img-fluid"
                            alt={getIntlData(siteSettings.title, intl.locale)}
                        ></GatsbyImage>
                    </div>

                    <div className="col-12 col-md-6">
                        <p>{getIntlData(data.allSanityAbout.nodes[0].body, intl.locale)}</p>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href={siteSettings.facebook}
                        >
                            Facebook
                        </a>{" "}
                        /{" "}
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href={siteSettings.instagram}
                        >
                            Instagram
                        </a>{" "}
                        <p>
                            {siteSettings.phone}
                            <br />
                            <a href={'mailto:'+siteSettings.email}>
                                {siteSettings.email}
                            </a>
                        </p>
                        <p>{siteSettings.address}</p>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export const query = graphql`
    query AboutPageQuery {
        allSanityAbout {
            nodes {
                body {
                    en
                    pt
                }
                image {
                    asset {
                        gatsbyImageData(placeholder:BLURRED, width:400)
                    }
                }
            }
        }
        allSanitySiteSettings {
            nodes {
                title {
                    pt
                    en
                }
                description {
                    pt
                    en
                }
                facebook
                instagram
                phone
                email
                address
            }
        }
    }
`

export default injectIntl(AboutPage);